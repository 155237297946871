// src/components/HomepageListingCarousel.js

import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ListingItem from './ListingItem';

const HomepageListingCarousel = ({ listings, onFavoriteChange }) => {
  const [centerSlidePercentage, setCenterSlidePercentage] = useState(28); 
  // 28% or 30% => about 3 slides with space

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 800) {
        // Smaller screen => show about 2 slides with space
        setCenterSlidePercentage(100); 
      } else {
        // Larger screen => show about 3 slides with space
        setCenterSlidePercentage(33);
      }
    }

    // Run once on mount
    handleResize();

    // Listen for window size changes
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="homepage-listings-carousel">
      <Carousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        autoPlay
        infiniteLoop
        centerMode
        centerSlidePercentage={centerSlidePercentage}
        interval={5000}
        emulateTouch
        swipeable
        stopOnHover
      >
        {listings.slice(0, 10).map((listing) => (
          <div key={listing.id} className="carousel-slide">
            <ListingItem
              listing={listing}
              onFavoriteChange={onFavoriteChange}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default HomepageListingCarousel;
