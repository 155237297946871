// src/pages/ListingDetail.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetchListingById } from '../api';
import { Carousel } from 'react-responsive-carousel';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Footer from './Footer';
import ContactAgentButton from '../components/ContactAgentButton';
import StreetViewModal from '../components/StreetViewModal';

import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

const ListingDetail = () => {
  const { id } = useParams();
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showStreetView, setShowStreetView] = useState(false);
  const streetViewRef = useRef(null);
  const streetViewPanoramaRef = useRef(null);

  useEffect(() => {
    const loadListing = async () => {
      try {
        const data = await fetchListingById(id);
        setListing(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadListing();
  }, [id]);

  const openStreetView = () => setShowStreetView(true);
  const closeStreetView = () => {
    setShowStreetView(false);
    // Reset the panorama ref so a new one is created next time
    streetViewPanoramaRef.current = null;
  };

  useEffect(() => {
    if (showStreetView && streetViewRef.current && listing?.latitude && listing?.longitude) {
      (async () => {
        if (!window.google?.maps?.importLibrary) {
          console.error("Google Maps JS not ready yet.");
          return;
        }
        await window.google.maps.importLibrary("maps");

        // Always create a new StreetViewPanorama instance when opening the modal
        streetViewPanoramaRef.current = new window.google.maps.StreetViewPanorama(
          streetViewRef.current,
          {
            position: { lat: listing.latitude, lng: listing.longitude },
            pov: { heading: 165, pitch: 0 },
            zoom: 1,
          }
        );
        streetViewPanoramaRef.current.setVisible(true);
      })();
    }
  }, [showStreetView, listing]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;
  if (!listing) return <div className="no-listing">No listing found.</div>;

  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(listing.price);

  const addressParts = [];
  if (listing.neighborhood) addressParts.push(listing.neighborhood);
  if (listing.city) addressParts.push(listing.city);
  if (listing.state) addressParts.push(listing.state);
  const formattedAddress = addressParts.join(', ');

  const sliderSettings = {
    showThumbs: true,
    showIndicators: true,
    infiniteLoop: true,
    autoPlay: false,
    interval: 5000,
  };

  return (
    <div className="listing-detail-page">
      <div className="listing-images">
        {listing.images && listing.images.length > 0 ? (
          <Carousel {...sliderSettings}>
            {listing.images.map((image, index) => (
              <div key={index} className="slide-image">
                <img src={image.image} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        ) : (
          <div className="no-images">No images available.</div>
        )}
      </div>

      <div className="listing-content-container">
        <div className="left-column">
          <div className="sticky-contact-button">
            <ContactAgentButton listingId={listing.id} />
          </div>
        </div>
        <div className="right-column">
          <div className="listing-header">
            <h1>{listing.title}</h1>
            <h2 className='price'>{formattedPrice}</h2>
            <p className="address">
              {listing.address || formattedAddress}
            </p>
          </div>

          <div className="property-details">
            <h3>Detalle de la Propiedad</h3>
            <ul>
              <li><strong>Tipo de Propiedad:</strong> {listing.property_type}</li>
              <li><strong>Tamaño:</strong> {listing.lot_area} m<sup>2</sup></li>
              <li><strong>Número de Cuartos:</strong> {listing.number_of_rooms}</li>
              <li><strong>Número de Baños:</strong> {listing.number_of_bathrooms}</li>
              <li><strong>Estacionamientos:</strong> {listing.number_of_parking_spots || 'N/D'}</li>
              <li><strong>Pisos:</strong> {listing.number_of_floors || 'N/D'}</li>
              <li><strong>Año de Construcción:</strong> {listing.year_built || 'N/D'}</li>
            </ul>
          </div>

          <div className="listing-description">
            <h3>Descripción</h3>
            <p>{listing.description}</p>
          </div>

          {listing.amenities && listing.amenities.length > 0 && (
            <div className="listing-amenities">
              <h3>Amenidades</h3>
              <ul>
                {listing.amenities.map((amenity, index) => (
                  <li key={index}>{amenity.name}</li>
                ))}
              </ul>
            </div>
          )}

          {listing.latitude && listing.longitude && (
            <div className="listing-map" style={{ position: 'relative' }}>
              <h3>Ubicación</h3>
              <MapContainer 
                center={[listing.latitude, listing.longitude]} 
                zoom={16} 
                style={{ height: '400px', width: '100%'}}
                dragging={true}
                attributionControl={false}
                scrollWheelZoom={true}
                doubleClickZoom={true}
              >
                <TileLayer
                  url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                  attribution=''
                />
                <Marker position={[listing.latitude, listing.longitude]}>
                  <Popup>
                    {formattedAddress}
                  </Popup>
                </Marker>
              </MapContainer>

              <button 
                onClick={openStreetView}
                className="street-view-button"
              >
                Street View
              </button>
            </div>
          )}

          <div className="listing-contact">
            <h3>Contacto</h3>
            {listing.original_listing_url && (
              <div className="original-listing">
                <a 
                  href={listing.original_listing_url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="original-listing-link"
                >
                  Listado Original
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />

      {showStreetView && (
        <StreetViewModal onClose={closeStreetView}>
          <div 
            id="street-view"
            ref={streetViewRef} 
            style={{ width: '800px', height: '400px', background: '#eee' }}
          ></div>
        </StreetViewModal>
      )}
    </div>
  );
};

export default ListingDetail;
