// src/pages/Homepage.js

import '../styles/styles.css';
import SearchBar from '../components/SearchBar';
import { useNavigate } from 'react-router-dom';
import '../styles/homepage.css';
import mtyImage from '../images/mty.jpg';
import sanPedroImage from '../images/san_pedro.jpg';
import santaCatarinaImage from '../images/santa_catarina.jpg';
import Footer from './Footer';
import React, { useEffect, useState } from 'react';
import HomepageListingCarousel from '../components/HomepageListingCarousel'; // Adjust path
import { fetchPaginatedListings } from '../api'; // Your existing function


const Homepage = () => {
  const navigate = useNavigate();

  // State for handling the rent/sale switch
  const [listingType, setListingType] = useState('venta'); // 'venta' or 'renta'

  // Handler for Mapbox SearchBar location selection
  const handleLocationSelect = (location) => {
    if (!location?.bbox) return;

    const [swLng, swLat, neLng, neLat] = location.bbox;
    if (swLng && swLat && neLng && neLat) {
      navigate(
        `/listings?swLat=${swLat}&swLng=${swLng}&neLat=${neLat}&neLng=${neLng}&listingType=${listingType}`
      );
    }
  };

  const [carouselListings, setCarouselListings] = useState([]);

  useEffect(() => {
    // Example bounding box values. Replace with actual coords if you have them:
    const swLat = 25.57;
    const swLng = -100.45;
    const neLat = 25.76;
    const neLng = -100.18;
    const listingType = 'renta'; // or 'renta', etc.

    // Wrap fetch in an async IIFE
    (async () => {
      try {
        const data = await fetchPaginatedListings(
          swLat,
          swLng,
          neLat,
          neLng,
          null, // minPrice
          null, // maxPrice
          null, // rooms
          listingType,
          false, // exactMatch
          1,     // page
          5     // perPage
        );

        // data.results is typically the array of listings. 
        // If your API structure is different, adjust accordingly.
        if (data && data.results) {
          // Show only the first 10 listings
          const firstTen = data.results.slice(0, 10);
          setCarouselListings(firstTen);
        }
      } catch (error) {
        console.error('Error fetching homepage listings:', error);
      }
    })();
  }, []);

  // Optional: if you need to handle favorites globally
  const handleFavoriteChange = () => {
    // e.g., re-fetch or update global state 
    console.log('Favorite status changed');
  };


  // Hardcoded city data
  const cities = [
    {
      name: 'Monterrey',
      url: `/listings?swLat=25.57053499939937&swLng=-100.44817046562524&neLat=25.75722957113736&neLng=-100.18602325255348&listingType=${listingType}`,
      imageUrl: mtyImage,
    },
    {
      name: 'San Pedro',
      url: `/listings?swLat=25.60013269137569&swLng=-100.43019088459293&neLat=25.71523334866312&neLng=-100.31173509781006&listingType=${listingType}`,
      imageUrl: sanPedroImage,
    },
    {
      name: 'Santa Catarina',
      url: `/listings?swLat=25.62571128314582&swLng=-100.51003145303319&neLat=25.733703792391168&neLng=-100.40016689093581&listingType=${listingType}`,
      imageUrl: santaCatarinaImage,
    },
  ];

  const handleCityClick = (url) => {
    navigate(url);
  };

  return (
    <div className="homepage">
      {/* Hero Section */}
      <div className="hero">
        <div className="hero-content">
          <h1>Encuentra tu Sitio</h1>

          {/* Rent/Buy switch directly above the SearchBar */}
          <div className="search-controls">
            <div className="switch-container">
              <button
                className={`switch-btn ${listingType === 'venta' ? 'active' : ''}`}
                onClick={() => setListingType('venta')}
              >
                Venta
              </button>
              <button
                className={`switch-btn ${listingType === 'renta' ? 'active' : ''}`}
                onClick={() => setListingType('renta')}
              >
                Renta
              </button>
            </div>

            <SearchBar onLocationSelect={handleLocationSelect} />
          </div>
        </div>
      </div>

      <section className="featured-properties">
        <h2>Propiedades Destacadas</h2>

        {/* Render the carousel only if listings are available */}
        {carouselListings.length > 0 && (
          <HomepageListingCarousel
            listings={carouselListings}
            onFavoriteChange={handleFavoriteChange}
          />
        )}
      </section>

      {/* City Boxes */}
      <section className="featured-properties">
        <h2>Ciudades Destacadas</h2>
        <div className="city-boxes">
          {cities.map((city) => (
            <div
              key={city.name}
              className="city-box"
              onClick={() => handleCityClick(city.url)}
            >
              <img src={city.imageUrl} alt={city.name} className="city-image" />
              <div className="city-name">{city.name}</div>
            </div>
          ))}
        </div>
      </section>

      

      {/* Become a Real Estate Agent */}
      <section className="become-agent">
        <h2>Únete</h2>
        <p>¿Eres agente independiente o quieres convertirte en uno?<br />
          Forma parte de nuestra red de agentes.</p>
          <a href="mailto:contacto@zittu.com.mx" className="agent-button">Contáctanos</a>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Homepage;
