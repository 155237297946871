import React, { useState, useEffect, useRef } from 'react';

const RoomsFilter = ({ onRoomsChange, appliedRooms, appliedExactMatch }) => {
  const [showRoomsFilter, setShowRoomsFilter] = useState(false);
  const [exactMatch, setExactMatch] = useState(appliedExactMatch || false);
  const [selectedRooms, setSelectedRooms] = useState(
    appliedRooms !== undefined ? appliedRooms : null
  );
  const roomsFilterRef = useRef(null);

  const options = exactMatch
    ? [
        { label: '0', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
      ]
    : [
        { label: 'Todas', value: null },
        { label: '1+', value: 1 },
        { label: '2+', value: 2 },
        { label: '3+', value: 3 },
      ];

  const handleClickOutside = (e) => {
    if (roomsFilterRef.current && !roomsFilterRef.current.contains(e.target)) {
      setShowRoomsFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const applyRoomsFilter = () => {
    onRoomsChange(selectedRooms, exactMatch);
    setShowRoomsFilter(false);
  };

  const buildButtonText = () => {
    if (appliedRooms !== null && appliedRooms !== undefined) {
      if (exactMatch) {
        return `${appliedRooms} Hab`;
      } else {
        return appliedRooms === null ? 'Habitaciones' : `${appliedRooms}+ Hab`;
      }
    } else {
      return 'Habitaciones';
    }
  };

  return (
    <div className="filter-button-container" ref={roomsFilterRef}>
      <button
        onClick={() => setShowRoomsFilter(!showRoomsFilter)}
        className="filter-button"
      >
        {buildButtonText()}
      </button>

      {showRoomsFilter && (
        <div className="filter-detail-rooms">
          <div className="filter-options">
            {options.map((option) => (
              <div
                key={option.value}
                className={`filter-option-item ${selectedRooms === option.value ? 'selected' : ''}`}
                onClick={() => setSelectedRooms(option.value)}
              >
                {option.label}
              </div>
            ))}
          </div>
          <div className="filter-exact-match-checkbox">
            <input
                type="checkbox"
                id="exactMatch"
                checked={exactMatch} /* This ensures it reflects the state */
                onChange={() => setExactMatch(!exactMatch)} /* Updates state on change */
            />
            <label
                htmlFor="exactMatch"
                className="filter-exact-match-checkbox-label"
            >
                Exacto
            </label>
        </div>
          <button
            type="button"
            onClick={applyRoomsFilter}
            className="apply-filter-button"
          >
            Aplicar
          </button>
        </div>
      )}
    </div>
  );
};

export default RoomsFilter;
