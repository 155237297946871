import React from 'react';
import '../styles/footer.css'; // Optional: If you have a separate CSS file for styling

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <h4><i>Encuentra tu sitio</i> </h4>
        <h4><a href="mailto:contacto@zittu.com.mx">Contacto</a></h4>
        <h4>Zittu 2024 </h4>
      </div>
    </footer>
  );
};

export default Footer;
