//Listings.js
import React, { useState, useEffect, useRef, useCallback} from 'react';
import { favoriteListing, unfavoriteListing, fetchMapMarkers, fetchPaginatedListings} from '../api';
import '../styles/styles.css';
import ListingsMap from '../components/ListingsMap';
import ListingItem from '../components/ListingItem';
import SearchBar from '../components/SearchBar';
import Pagination from '../components/Pagination';
import PriceFilter from '../components/PriceFilter';  // Import the PriceFilter
import RoomsFilter from '../components/RoomsFilter'; // Import the RoomsFilter
import ListingTypeFilter from '../components/ListingTypeFilter';
import { useLocation, useNavigate } from 'react-router-dom'; // Import React Router hooks
import Footer from './Footer';

const Listings = () => {
    const [listings, setListings] = useState([]);
    const [mapBounds, setMapBounds] = useState(null);
    const mapRef = useRef(null);
    const [markers, setMarkers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [highlightedMarkerId, setHighlightedMarkerId] = useState(null);
    const listingsRef = useRef(null);
    const [minPrice, setMinPrice] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);
    const [rooms, setRooms] = useState(null); // Add state for rooms
    const [exactMatch, setExactMatch] = useState(false); // Add state for exact match
    const location = useLocation(); // To access current URL's query params
    const navigate = useNavigate(); // To programmatically navigate or update the URL
    const isInitialMount = useRef(true);
    const isProgrammatic = useRef(false);
    const [listingType, setListingType] = useState(null);

    const handleListingTypeChange = (newListingType) => {
        setListingType(newListingType);
        setCurrentPage(1);
    };
   
    const updateURL = (newParams, replace = false) => {
        const searchParams = new URLSearchParams(location.search);
        let urlChanged = false;
    
        // Update searchParams with newParams
        Object.keys(newParams).forEach((key) => {
            const newValue = newParams[key];
            const currentValue = searchParams.get(key);
            if (newValue === null || newValue === undefined) {
                if (currentValue !== null) {
                    searchParams.delete(key);
                    urlChanged = true;
                }
            } else {
                if (currentValue !== newValue.toString()) {
                    searchParams.set(key, newValue);
                    urlChanged = true;
                }
            }
        });
    
        if (urlChanged) {
            // Construct the new URL
            const newSearch = searchParams.toString();
            navigate({
                pathname: location.pathname,
                search: newSearch ? `?${newSearch}` : '',
            }, { replace });
        }
    };
    

    const parseQueryParams = () => {
        const searchParams = new URLSearchParams(location.search);
        const min = searchParams.get('minPrice') ? parseInt(searchParams.get('minPrice'), 10) : null;
        const max = searchParams.get('maxPrice') ? parseInt(searchParams.get('maxPrice'), 10) : null;
    
        // Retrieve map bounds from query parameters
        const swLat = searchParams.get('swLat');
        const swLng = searchParams.get('swLng');
        const neLat = searchParams.get('neLat');
        const neLng = searchParams.get('neLng');
        const page = parseInt(searchParams.get('page'));
        setCurrentPage(!isNaN(page) ? page : 1);

        const listingTypeParam = searchParams.get('listingType');
        setListingType(listingTypeParam || null);

        // Update price filters based on URL parameters
        setMinPrice(!isNaN(min) ? min : null);
        setMaxPrice(!isNaN(max) ? max : null);
        console.log("Prices updated from URL:");
       
        const roomsParam = searchParams.get('rooms');
        const exactMatchParam = searchParams.has('exactMatch'); // Set to true if present, false if absent
        setRooms(roomsParam !== null ? parseInt(roomsParam, 10) : null);
        setExactMatch(exactMatchParam);

        // Validate and parse the bounds coordinates
        const parsedSwLat = parseFloat(swLat);
        const parsedSwLng = parseFloat(swLng);
        const parsedNeLat = parseFloat(neLat);
        const parsedNeLng = parseFloat(neLng);
    
        if (
            !isNaN(parsedSwLat) &&
            !isNaN(parsedSwLng) &&
            !isNaN(parsedNeLat) &&
            !isNaN(parsedNeLng)
        ) {
            // Round coordinates to 6 decimal places
            const roundedBounds = {
                getSouth: () => parsedSwLat,
                getWest: () => parsedSwLng,
                getNorth: () => parsedNeLat,
                getEast: () => parsedNeLng,
            };
    
            setMapBounds(roundedBounds);
            console.log("Bounds updated from URL:");
        } else {
            console.log("Invalid bounds parameters in URL.");
        }
    };
    
    const handleFavorite = async (listing) => {
        try {
            if (listing.is_favorited) {
                // Unfavorite the listing
                await unfavoriteListing(listing.id);
            } else {
                // Favorite the listing
                await favoriteListing(listing.id);
            }
            // Update the listings state with the new favorite status
            setListings((prevListings) =>
                prevListings.map((item) =>
                    item.id === listing.id ? { ...item, is_favorited: !item.is_favorited } : item
                )
            );
        } catch (error) {
            console.error('Error updating favorite status:', error);
        }
    };


    /**
     * Function to fetch data based on current state.
     * @param {Object} bounds - The current map bounds.
     * @param {number|null} min - The minimum price filter.
     * @param {number|null} max - The maximum price filter.
     * @param {number} page - The current page number.
     */
    const fetchData = async (bounds, min, max, page = 1, rooms=null, listingType = null, exactMatch=false) => {
        try {
            // Fetch all markers within bounds with effective prices
            const markerData = await fetchMapMarkers(
                bounds.getSouth(),
                bounds.getWest(),
                bounds.getNorth(),
                bounds.getEast(),
                min,
                max,
                rooms,
                listingType,
                exactMatch
            );
            setMarkers(markerData);

            // Fetch paginated listings with effective prices
            const listingData = await fetchPaginatedListings(
                bounds.getSouth(),
                bounds.getWest(),
                bounds.getNorth(),
                bounds.getEast(),
                min,
                max,
                rooms,
                listingType,
                exactMatch,
                page,
                20,
            );
            setListings(listingData.results);  // Adjust according to response structure
            setTotalPages(Math.ceil(listingData.count / 20));  // Calculate total pages
            setCurrentPage(page);  // Set current page
        } catch (err) {
            console.error(err.message);
        }
    };

    const handleBoundsChange = async (bounds) => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            console.log('finished initial mount');
        } else {
            setCurrentPage(1);
            setMapBounds(bounds);
        }
    };
    

    // Custom debounce function
    const debounce = (func, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => func.apply(this, args), delay);
        };
    };
    
    /**
     * Handler for page changes.
     * @param {number} page - The new page number.
     */
    const handlePageChange = debounce(async (page) => {
        setCurrentPage(page);
    },300);

    // Add handleRoomsChange function
  const handleRoomsChange = (selectedRooms, isExactMatch) => {
    setRooms(selectedRooms);
    setExactMatch(isExactMatch);
    setCurrentPage(1);
  };

    
    const handleLocationSelect = useCallback((location) => {
        const { center } = location;
        if (center) {
            if (mapRef.current) {
                mapRef.current.panTo([center[0], center[1]]);
                const bounds = mapRef.current.getBounds();
                    if (handleBoundsChange) {
                        isProgrammatic.current=true
                        handleBoundsChange(bounds);
                    };
            }
        }
    }, [handleBoundsChange]);

    useEffect(() => {
        // Reset highlighted marker when listings change
        if (highlightedMarkerId !== null) {
            mapRef.current?.resetMarker(highlightedMarkerId);
            setHighlightedMarkerId(null);
        }
        if (listingsRef.current) {
            listingsRef.current.scrollTo({
              top: 0,
            });
          }
    }, [listings]);

    
    useEffect(() => {
        // Load initial listings and set bounds from URL parameters on component mount
        parseQueryParams(); // Ensure parameters are parsed
    }, []);

    useEffect(() => {

        if (isProgrammatic.current) {
          isProgrammatic.current = false; // Use = for assignment
          return;
        }
      
        if (mapBounds && !isProgrammatic.current) {
          // Fetch data when mapBounds, prices, or page changes
          fetchData(mapBounds, minPrice, maxPrice, currentPage, rooms, listingType, exactMatch);
          console.log('data fetched');
          // Update URL if mapBounds and other parameters exist and it's not the initial mount
          if (!isInitialMount.current) {
            
            updateURL(
              {
                swLat: mapBounds.getSouth(),
                swLng: mapBounds.getWest(),
                neLat: mapBounds.getNorth(),
                neLng: mapBounds.getEast(),
                minPrice,
                maxPrice,
                page: currentPage,
                rooms,
                listingType: listingType || undefined,
                exactMatch: exactMatch ? true : undefined // Only include exactMatch if true
              },
              true
            );
          }
        }
      }, [mapBounds, minPrice, maxPrice, currentPage, rooms, exactMatch, listingType]);
      
      

    //useEffect(() => {
    //    parseQueryParams(); // Parse URL on mount and when search params change
    //}, [location.search]);
    

    const handlePriceChange = (min, max) => {
        setMinPrice(min);
        setMaxPrice(max);
        if (currentPage) {
            setCurrentPage(1);
        } 
      };

    return (
        <div className="container">
            <div className="filters-container">
                <SearchBar onLocationSelect={handleLocationSelect} />
                <div className="filters-parameters-container">
                    <ListingTypeFilter
                        onListingTypeChange={handleListingTypeChange}
                        appliedListingType={listingType}
                    />
                    <PriceFilter 
                        onPriceChange={handlePriceChange} 
                        appliedMinPrice={minPrice} 
                        appliedMaxPrice={maxPrice} 
                    />
                    <RoomsFilter
                        onRoomsChange={handleRoomsChange}
                        appliedRooms={rooms}
                        appliedExactMatch={exactMatch}
                    />
                </div>
            </div>
            <div className="content-container">
                <div className="listings-column" ref={listingsRef}>
                    <div className="listings">
                        {listings.map((listing) => (
                            <ListingItem
                                key={listing.id}
                                listing={listing}
                                onFavoriteChange={() => handleFavorite(listing)}
                                onHover={() => {
                                    mapRef.current?.highlightMarker(listing.id);
                                    setHighlightedMarkerId(listing.id);
                                }}
                                onLeave={() => {
                                    mapRef.current?.resetMarker(listing.id);
                                    setHighlightedMarkerId(null);
                                }}
                            />
                        ))}
                                       
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        />
                    <Footer />
                </div>
                <div className="map-column">
                    <ListingsMap 
                        markers={markers} 
                        onBoundsChange={handleBoundsChange} 
                        mapRef={mapRef}
                        onFavoriteChange={() => handleFavorite()}
                        mapBounds = {mapBounds}/>
                        
                </div>
            </div>
        </div>
    );
};

export default Listings;
