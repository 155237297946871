import React, { useState, useEffect, useRef } from 'react';

const PriceFilter = ({ onPriceChange, appliedMinPrice, appliedMaxPrice }) => {
  const [showPriceFilter, setShowPriceFilter] = useState(false);
  const [inputMinPrice, setInputMinPrice] = useState(appliedMinPrice || '');
  const [inputMaxPrice, setInputMaxPrice] = useState(appliedMaxPrice || '');
  const [showMinDropdown, setShowMinDropdown] = useState(false);
  const [showMaxDropdown, setShowMaxDropdown] = useState(false);
  const [isApplying, setIsApplying] = useState(false);
  const priceFilterRef = useRef(null);
  const minPriceRef = useRef(null);
  const maxPriceRef = useRef(null);

  const priceOptions = Array.from({ length: 50 }, (_, i) => (i + 1) * 500000);

  const formatPrice = (price) => {
    if (price >= 1000000) {
      const million = price / 1000000;
      return million % 1 === 0 ? `${million}M` : `${million.toFixed(1)}M`;
    } else if (price >= 1000) {
      const thousand = price / 1000;
      return thousand % 1 === 0 ? `${thousand}K` : `${thousand.toFixed(1)}K`;
    } else {
      return `$${price.toLocaleString()}`;
    }
  };

  const applyPriceFilter = (e) => {
    e.preventDefault();

    if (isApplying) return;
    setIsApplying(true);

    // Helper function to remove non-numeric characters and parse as integer
    const parsePrice = (price) => {
        if (typeof price === "string") {
            return parseInt(price.replace(/[^0-9]/g, ""), 10);
        }
        return parseInt(price, 10); // Handle case if already a number
    };

    const min = parsePrice(inputMinPrice);
    const max = parsePrice(inputMaxPrice);

    if (!isNaN(min) && !isNaN(max) && min > max) {
        alert('El precio Mínimo no puede ser mayor al Máximo');
        setIsApplying(false);
        return;
    }

    const finalMin = !isNaN(min) ? min : null;
    const finalMax = !isNaN(max) ? max : null;

    // Call onPriceChange directly without debounce
    onPriceChange(finalMin, finalMax);
    setShowPriceFilter(false);
    setIsApplying(false);
};

  const handleMinPriceSelect = (price) => {
    setInputMinPrice(`$${price.toLocaleString()}`);
    setShowMinDropdown(false);
  };

  const handleMaxPriceSelect = (price) => {
    setInputMaxPrice(`$${price.toLocaleString()}`);
    setShowMaxDropdown(false);
  };

  const handleClickOutside = (e) => {
    if (priceFilterRef.current && !priceFilterRef.current.contains(e.target)) {
      setShowPriceFilter(false);
      setShowMinDropdown(false);
      setShowMaxDropdown(false);
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Escape') {
      setShowPriceFilter(false);
      setShowMinDropdown(false);
      setShowMaxDropdown(false);
      e.target.blur();
    } else if (e.key === 'Enter') {
      e.preventDefault();
      applyPriceFilter(e);
      e.target.blur();
    }
  };

  const handleMinInputFocus = () => {
    setShowMinDropdown(true);
  };

  const handleMaxInputFocus = () => {
    setShowMaxDropdown(true);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const buildButtonText = () => {
    if (appliedMinPrice !== null && appliedMaxPrice !== null) {
      return `$${formatPrice(appliedMinPrice)} - $${formatPrice(appliedMaxPrice)}`;
    } else if (appliedMinPrice !== null && appliedMaxPrice === null) {
      return `≥ $${formatPrice(appliedMinPrice)}`;
    } else if (appliedMinPrice === null && appliedMaxPrice !== null) {
      return `≤ $${formatPrice(appliedMaxPrice)}`;
    } else {
      return 'Precio';
    }
  };

  return (
    <div className="filter-button-container" ref={priceFilterRef}>
      <button
        onClick={() => setShowPriceFilter(!showPriceFilter)}
        className="filter-button"
      >
        {appliedMinPrice !== null || appliedMaxPrice !== null
          ? buildButtonText()
          : 'Precio'}
      </button>

      {showPriceFilter && (
        <div className="filter-detail-price">
          <div className="filter-label">Min</div>
          <div className="filter-input" ref={minPriceRef}>
            <input
              type="text"
              value={inputMinPrice}
              onFocus={handleMinInputFocus}
              onClick={handleMinInputFocus} // Added onClick handler
              onChange={(e) => setInputMinPrice(e.target.value)}
              placeholder="Precio Mínimo"
              min="0"
              step="250000"
              onKeyDown={handleInputKeyPress}
            />
            {showMinDropdown && (
              <div className="filter-dropdown">
                {priceOptions.map((price) => (
                  <div
                    key={price}
                    className="filter-dropdown-item"
                    onClick={() => handleMinPriceSelect(price)}
                  >
                    ${price.toLocaleString()}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="filter-label">Max</div>
          <div className="filter-input" ref={maxPriceRef}>
            <input
              type="text"
              value={inputMaxPrice}
              onFocus={handleMaxInputFocus}
              onClick={handleMaxInputFocus} // Added onClick handler
              onChange={(e) => setInputMaxPrice(e.target.value)}
              placeholder="Precio Máximo"
              min="0"
              step="250000"
              onKeyDown={handleInputKeyPress}
            />
            {showMaxDropdown && (
              <div className="filter-dropdown">
                {priceOptions.map((price) => (
                  <div
                    key={price}
                    className="filter-dropdown-item"
                    onClick={() => handleMaxPriceSelect(price)}
                  >
                    ${price.toLocaleString()}
                  </div>
                ))}
              </div>
            )}
          </div>

          <button
            type="button"
            onClick={applyPriceFilter}
            className="apply-filter-button"
            disabled={isApplying}
          >
            Enviar
          </button>
        </div>
      )}
    </div>
  );
};

export default PriceFilter;
